import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  CURRENT_PAGE_ID = 'currentPage';
  SELECTED_COMPETITORS = 'selectedCompetitors';
  SELECTED_IG_COMPETITORS = 'selectedIgCompetitors';
  USER = 'user';
  RENDER_IG_ANALYTICS = 'renderInstagram';
  RENDER_ANALYTICS = 'renderAnalytics';
  RENDER_TWITTER = 'render_twitter_analytics';
  RENDER_BENCHMARKS = 'renderBenchmarks';
  START_DATE = 'startDate';
  END_DATE = 'endDate';
  JWT_TOKEN = 'JWToken';
  SECTIONS = 'sections';
  SECTIONS1 = 'sections-instagram';
  constructor() {}
  getCurrentPageID() {
    return localStorage.getItem(this.CURRENT_PAGE_ID);
  }
  setCurrentPageID(pageID) {
    return localStorage.setItem(this.CURRENT_PAGE_ID, pageID);
  }

  // fb competitors
  getSelectedCompetitors() {
    const data = localStorage.getItem(this.SELECTED_COMPETITORS);
    if (!data) {
      this.setSelectedCompetitors({});
      return {};
    } else {
      return JSON.parse(data);
    }
  }
  setSelectedCompetitors(selectedCompetitors) {
    return localStorage.setItem(
      this.SELECTED_COMPETITORS,
      JSON.stringify(selectedCompetitors)
    );
  }

  addSelectedCompetitor(pageID, compID) {
    if (!pageID || !compID) {
      return;
    }
    const selected = this.getSelectedCompetitors();
    if (selected[pageID]) {
      const exist = selected[pageID].find((e) => e === compID);
      if (!exist) {
        selected[pageID] = [...selected[pageID], compID];
      }
    } else {
      selected[pageID] = [compID];
    }
    this.setSelectedCompetitors(selected);
  }

  removeSelectedCompetitor(pageID, compID) {
    if (!pageID || !compID) {
      return;
    }
    const selected: any = this.getSelectedCompetitors();
    if (selected[pageID]) {
      selected[pageID] = selected[pageID].filter((e) => e !== compID);
    }
    this.setSelectedCompetitors(selected);
  }

  // ig competitors
  getSelectedIgCompetitors() {
    const data = localStorage.getItem(this.SELECTED_IG_COMPETITORS);
    if (!data) {
      this.setSelectedIgCompetitors({});
      return {};
    } else {
      return JSON.parse(data);
    }
  }
  setSelectedIgCompetitors(selectedCompetitors) {
    return localStorage.setItem(
      this.SELECTED_IG_COMPETITORS,
      JSON.stringify(selectedCompetitors)
    );
  }

  addSelectedIgCompetitor(pageID, compID) {
    if (!pageID || !compID) {
      return;
    }
    const selected = this.getSelectedIgCompetitors();
    if (selected[pageID]) {
      const exist = selected[pageID].find((e) => e === compID);
      if (!exist) {
        selected[pageID] = [...selected[pageID], compID];
      }
    } else {
      selected[pageID] = [compID];
    }
    this.setSelectedIgCompetitors(selected);
  }

  removeSelectedIgCompetitor(pageID, compID) {
    if (!pageID || !compID) {
      return;
    }
    const selected: any = this.getSelectedIgCompetitors();
    if (selected[pageID]) {
      selected[pageID] = selected[pageID].filter((e) => e !== compID);
    }
    this.setSelectedIgCompetitors(selected);
  }

  removeCurrentPageID() {
    return localStorage.removeItem(this.CURRENT_PAGE_ID);
  }
  getStartDate() {
    return localStorage.getItem(this.START_DATE);
  }
  setStartDate(startDate) {
    return localStorage.setItem(this.START_DATE, startDate);
  }
  getEndDate() {
    return localStorage.getItem(this.END_DATE);
  }
  setEndDate(endDate) {
    return localStorage.setItem(this.END_DATE, endDate);
  }
  getJWToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }
  setJWToken(growApp) {
    return localStorage.setItem(this.JWT_TOKEN, growApp);
  }
  getUser() {
    return JSON.parse(localStorage.getItem(this.USER));
  }
  setUser(user) {
    return localStorage.setItem(this.USER, user);
  }
  getSection() {
    return localStorage.getItem(this.SECTIONS);
  }
  setSection(sections) {
    return localStorage.setItem(this.SECTIONS, sections);
  }
  getSection1() {
    return localStorage.getItem(this.SECTIONS1);
  }
  setSection1(sections) {
    return localStorage.setItem(this.SECTIONS1, sections);
  }
  clearStorage() {
    localStorage.clear();
    this.deleteAllCookies();
  }
  deleteAllCookies() {
    try {
      const cookies = document.cookie.split(';');
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      }
    } catch (error) {
      console.log({ error });
    }
  }

  getTwitterRenderAnalytics() {
    return JSON.parse(localStorage.getItem(this.RENDER_TWITTER));
  }
  setTwitterRenderAnalytics(renderTwitterAnalytics) {
    return localStorage.setItem(
      this.RENDER_TWITTER,
      JSON.stringify(renderTwitterAnalytics)
    );
  }
  getRenderAnalytics() {
    return JSON.parse(localStorage.getItem(this.RENDER_ANALYTICS));
  }
  setRenderAnalytics(renderAnalytics) {
    return localStorage.setItem(
      this.RENDER_ANALYTICS,
      JSON.stringify(renderAnalytics)
    );
  }
  getRenderBenchmarks() {
    return JSON.parse(localStorage.getItem(this.RENDER_BENCHMARKS));
  }
  setRenderBenchmarks(renderBenchmarks) {
    return localStorage.setItem(
      this.RENDER_BENCHMARKS,
      JSON.stringify(renderBenchmarks)
    );
  }

  getRenderIgAnalytics() {
    return JSON.parse(localStorage.getItem(this.RENDER_IG_ANALYTICS));
  }
  setRenderIgAnalytics(renderIgAnalytics) {
    return localStorage.setItem(
      this.RENDER_IG_ANALYTICS,
      JSON.stringify(renderIgAnalytics)
    );
  }
}
