import { INavData } from '@coreui/angular/lib/sidebar/public_api';

export const navItems: INavData[] = [
  {
    title: true,
    name: 'Menu',
  },
  {
    name: 'Home',
    url: '/dashboard',
    icon: 'icon-home',
  },
  {
    name: 'Users',
    url: '/dashboard/users',
    icon: 'icon-people',
    children: [
      {
        name: 'All Users',
        url: '/dashboard/users/all',
        icon: 'icon-user',
      }
    ],
  },
  {
    name: 'Agencies',
    url: '/dashboard/agency',
    icon: 'icon-flag',
  },
  {
    name: 'Social Accounts',
    url: '/dashboard/social',
    icon: 'icon-star',
  },
  {
    name: 'Plans',
    url: '/dashboard/plans/all',
    icon: 'icon-menu',
  },
  {
    name: 'Plan Requests',
    url: '/dashboard/plans/requests',
    icon: 'icon-notebook',
  },
  {
    name: 'Addons',
    url: '/dashboard/addons/all',
    icon: 'icon-check',
  },
  {
    name: 'Promos',
    url: '/dashboard/promos/all',
    icon: 'icon-bell',
  },
];

export const oldNavItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW',
    },
  },
  {
    title: true,
    name: 'Title',
  },
  {
    name: 'Disabled',
    url: '/dashboard',
    icon: 'icon-ban',
    attributes: { disabled: true },
  },
  {
    name: 'Download CoreUI',
    url: 'http://coreui.io/angular/',
    icon: 'icon-cloud-download',
    class: 'mt-auto',
    variant: 'success',
    attributes: { target: '_blank', rel: 'noopener' },
  },
  {
    name: 'Try CoreUI PRO',
    url: 'http://coreui.io/pro/angular/',
    icon: 'icon-layers',
    variant: 'danger',
    attributes: { target: '_blank', rel: 'noopener' },
  },
];
