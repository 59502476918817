import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../services/local-storage.service';
import { Router } from '@angular/router';
export const InterceptorSkipHeader = 'X-Skip-Interceptor';
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private storage: LocalStorageService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = this.storage.getJWToken();
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }
    const authReq = request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return next.handle(authReq).pipe(
      tap({
        error: (res) => {
          this.handleAuthError(res);
        },
      })
    );
  }

  handleAuthError(err: HttpErrorResponse): any {
    if (err.status === 401 || err.status === 403) {
      if (err.error) {
        this.storage.clearStorage();
        this.router.navigate(['/login']);
      }
    }
    return err;
  }

  isError(res: HttpErrorResponse): boolean {
    return res && res.status === 500;
  }

  isUnauthorized(res: HttpErrorResponse): boolean {
    return res && res.status === 401;
  }

  isForbidden(res: HttpErrorResponse): boolean {
    return res && res.status === 403;
  }
}
