import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../services/local-storage.service';
@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private httpClient: HttpClient,
    private storageService: LocalStorageService
  ) {}
  JWToken = 'JW_token';

  getProfile(): Observable<any> {
    try {
      return this.httpClient.get(`${environment.backendHost}/account`);
    } catch (err) {
      throw err;
    }
  }

  loginUser(email: string, password: string): Observable<any> {
    try {
      const payload = {
        username: email,
        password,
      };
      return this.httpClient.post(
        `${environment.backendHost}/authenticate`,
        payload
      );
    } catch (err) {
      throw err;
    }
  }

  loggedIn() {
    return !!this.storageService.getJWToken();
  }

  getToken() {
    return this.storageService.getJWToken();
  }

  setToken(token: string) {
    return this.storageService.setJWToken(token);
  }
}
