import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { LocalStorageService } from '../services/local-storage.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private storage: LocalStorageService,
    private auth: AuthService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const user = this.storage.getUser();
    if (this.auth.loggedIn() && user?.authorities?.includes('ROLE_ADMIN')) {
      return true;
    } else {
      this.router.navigateByUrl('/login');
      return false;
    }
  }
}
